import React from 'react';
import { Modal } from 'react-bootstrap';
import apiClient from '../../Api/api_client';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default class CurrentSweeps extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sweepsData: [],
            show: false,
        };
    }

    handleClose = () => {
        this.setState({ show: false });
    };

    handleShow = () => {
        this.setState({ show: true });
    };

    handleKeyShow = event => {
        if (event.key === 'Enter') {
            this.setState({ show: true });
        }
    };

    handleKeyClose = event => {
        if (event.key === 'Enter') {
            this.setState({ show: false });
        }
    };

    async componentDidMount() {
        try {
            const result = await apiClient().getSweeps({ sweeps_id: this.props.sweepId });
            this.setState({ sweepsData: result });
        } catch (error) {
            console.error('sweepsAPI error: ' + error);
        }
    }

    render() {
        const buildTable = () => {
            if (this.state.sweepsData[0] && this.state.sweepsData[0].drawing_groups.length > 0) {
                const formatDate = inputDate => {
                    return moment.unix(inputDate).format('MM/DD/YYYY');
                };

                const drawingEntries = thisDrawing => {
                    if (thisDrawing.drawing_summaries && thisDrawing.drawing_summaries.length > 0 && thisDrawing.drawing_summaries[0].entry_count) {
                        return thisDrawing.drawing_summaries[0].entry_count;
                    } else {
                        return String.fromCharCode(8212);
                    }
                };

                const prizeListItems = prizes => {
                    const sortedPrizes = prizes.sort((a, b) => (a.rank > b.rank ? 1 : -1));

                    sortedPrizes.forEach(prize => {
                        prize.quantityString = prize.quantity > 1 ? '(' + prize.quantity + ' winners)' : '';
                    });

                    return (
                        <>
                            {sortedPrizes.map(prize => {
                                return (
                                    <li key={`"prize-item-"${prize.rank}`}>
                                        {prize.name} {prize.quantityString}
                                    </li>
                                );
                            })}
                        </>
                    );
                };

                return (
                    <table key='sweep-table-{this.state.sweepsData.id}' className='sweeps-table'>
                        <caption>{this.state.sweepsData[0].name}</caption>
                        <thead>
                            <tr>
                                <th></th>
                                <th className='text-left'>Prizes</th>
                                <th className='text-center'>Entry Period</th>
                                <th className='text-center'>Announce By</th>
                                <th className='text-center'>Number of Entries</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.sweepsData[0].drawing_groups.map(drawing_group => {
                                return (
                                    <React.Fragment key={drawing_group.id}>
                                        {drawing_group.drawings.map(drawing => {
                                            return (
                                                <tr key={'draw-' + drawing.id}>
                                                    <td data-title='Drawing' className='text-left'>
                                                        {drawing_group.name}
                                                    </td>
                                                    <td data-title='Prizes' className='text-left'>
                                                        <ul className='list-unstyled prizes-list'>{prizeListItems(drawing_group.prizes)}</ul>
                                                    </td>
                                                    <td data-title='Entry Period' className='text-center'>
                                                        {formatDate(drawing.quantum_start)} - {formatDate(drawing.quantum_end)}
                                                    </td>
                                                    <td data-title='Announce By' className='text-center'>
                                                        {formatDate(drawing.draw_date)}
                                                    </td>
                                                    <td data-title='Number of Entries' className='text-center'>
                                                        {drawingEntries(drawing)}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                );
            }
        };

        return (
            <React.Fragment key={this.key}>
                <a role='button' tabIndex='0' onKeyDown={this.handleKeyShow} onClick={this.handleShow} className='cursor-pointer'>
                    {this.props.linkText}
                </a>

                <Modal dialogClassName='scbz-modal current-sweeps-modal' show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header>
                        <a className='modal-close-element' title='Close' tabIndex='0' role='button' onKeyDown={this.handleKeyClose} onClick={this.handleClose}>
                            <FontAwesomeIcon icon={faTimesCircle} size='3x' />
                        </a>
                    </Modal.Header>
                    <Modal.Body>{buildTable()}</Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

CurrentSweeps.defaultProps = {
    linkText: 'View Drawings',
};
