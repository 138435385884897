export default {
    drawticketentered: 'Draw Ticket Entered',
    instantticketentered: 'Scratch-Off/Fast Play Ticket Entered',
    surveycompleted: 'Survey Completed',
    playerawarded: 'Player Awarded',
    accountconfirmed: 'Account Confirmed',
    accountverified: 'Account Verified',
    facebook: 'Facebook',
    twitter: 'Twitter',
    youtube: 'Youtube',
    instagram: 'Instagram',
    ilotterybuy: 'iLottery Buy',
    temporal: 'Temporal',
    wager: 'Wager',
    deposit: 'Deposit',
};
