import React from 'react';
import DatePicker from 'react-datepicker';
import ContentLoading from '../widgets/content_loading';
import signals from './signals';
import pluralize from 'pluralize';
import 'react-datepicker/dist/react-datepicker.css';
import { titleCase } from 'title-case';
import FilterIcon from './filter_icon';
import { getText } from '../../utils/cms_copy_provider';
import promotion from '../../../promotion_config';
import CurrentSweeps from '../postclaim/currentSweeps';

export default class RewardHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pagesize: 5,

            // These state values are put on later for filtering,
            // but can also be set here:
            // startdate: null,
            // enddate: null,
            // currency: null,
            // signal: null,
        };

        this.getMoreRewards = this.getMoreRewards.bind(this);
    }

    componentDidMount() {
        this.props.actions.rewardActions.getRewards(this.state);
    }

    async getMoreRewards() {
        await this.setState({
            page: this.state.page + 1,
            pagesize: this.state.pagesize,
        });

        this.props.actions.rewardActions.getRewards(this.state);
    }

    async handleStartDateChange(date) {
        if (date) {
            await this.setState({
                startdate: date.getTime() / 1000,
                page: 1,
            });
        } else {
            await this.setState({
                startdate: null,
            });
        }
        await this.props.actions.rewardActions.clearRewards();
        await this.props.actions.rewardActions.getRewards(this.state);
    }

    async handleEndDateChange(date) {
        if (date) {
            await this.setState({
                enddate: date.getTime() / 1000,
                page: 1,
            });
        } else {
            await this.setState({
                enddate: null,
            });
        }

        await this.props.actions.rewardActions.clearRewards();
        await this.props.actions.rewardActions.getRewards(this.state);
    }

    async handleCurrencyChange(e) {
        await this.setState({
            currency: e.target.value,
            page: 1,
        });

        await this.props.actions.rewardActions.clearRewards();
        await this.props.actions.rewardActions.getRewards(this.state);
    }

    render() {
        const { rewards } = this.props.reward;

        // if there are more rewards, show the link for more
        const loadMoreLink =
            rewards.length > 0 && rewards.length == this.state.pagesize * this.state.page ? (
                <button className='more-button' onClick={this.getMoreRewards}>
                    {getText('rewards_btn_more')}
                </button>
            ) : null;

        const drawEntries = rewards => {
            if (!rewards || rewards.length === 0) {
                return (
                    <tbody>
                        <tr key='0'>
                            <td data-title='' colSpan='5'>
                                <em>{getText('rewards_msg_norewards')}</em>
                            </td>
                        </tr>
                    </tbody>
                );
            } else {
                // note we are spreading entries into a new array so we don't mutate the
                // entries array in state
                return (
                    <tbody>
                        {[...rewards].map(
                            ({ reward_id, sku, state, signal_name, prize_name, promotion_name, created_at, actual_amount, currency, entry_data }) => {
                                const resolvedCurrency = promotion.resolveCurrency(currency, getText);

                                return (
                                    <tr key={reward_id}>
                                        <td data-title={getText('rewards_column_date')}>{new Date(created_at * 1000).toDateString()}</td>
                                        <td data-title={getText('rewards_column_activity')}>
                                            <div className='name'>{signals[signal_name]}</div>
                                            <div className='key'>{entry_data}</div>
                                        </td>
                                        <td data-title={getText('rewards_column_promotion')}>{promotion_name}</td>
                                        <td data-title={getText('rewards_column_reward')}>
                                            <div key={`${created_at}${actual_amount}`}>
                                                <div className='amount'>{resolvedCurrency.resolve(actual_amount)}</div>
                                                <div className='prize'>
                                                    {!resolvedCurrency.isPence ? pluralize(titleCase(prize_name), actual_amount, false) : ''}
                                                </div>
                                            </div>
                                        </td>
                                        <td data-title=''>
                                            <CurrentSweeps key={reward_id.toString()} sweepId={sku.toString()} linkText={getText('rewards_lnk_viewdrawings')} />
                                        </td>
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                );
            }
        };

        const entrylist = drawEntries(rewards);

        return (
            <div className='enter'>
                <ContentLoading hasIcon={true} loadedAction='getEntries'>
                    <div className='row justify-content-end'>
                        <div className='col-12 col-md-5'>
                            <div className='filter-text-area'>
                                <div className='filter-text'>{getText('rewards_filter_filterbydate')}</div>
                                <DatePicker
                                    className='mini-date-selector'
                                    selected={this.state.startdate * 1000}
                                    onChange={this.handleStartDateChange.bind(this)}
                                    placeholderText='Start'
                                    popperModifiers={{
                                        preventOverflow: {enabled: true}
                                    }}
                                />
                                <div className='filter-text'>to</div>
                                <DatePicker
                                    className='mini-date-selector'
                                    selected={this.state.enddate * 1000}
                                    onChange={this.handleEndDateChange.bind(this)}
                                    placeholderText='End'
                                    popperModifiers={{
                                        preventOverflow: {enabled: true}
                                    }}
                                />
                                <FilterIcon />
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='filter-rewards'>
                                <select name='currency' value={this.state.currency} onChange={this.handleCurrencyChange.bind(this)} className='form-control'>
                                    <option value=''>{getText('rewards_filter_filterbyrewards')}</option>
                                    {[...rewards]
                                        .map(({ currency }) => {
                                            return currency;
                                        })
                                        .filter((value, index, self) => {
                                            return self.indexOf(value) === index;
                                        })
                                        .map(item => {
                                            const resolvedCurrency = promotion.resolveCurrency(item, getText);
                                            return (
                                                <option key={item} value={item}>
                                                    {resolvedCurrency.displayName}
                                                </option>
                                            );
                                        })}
                                </select>
                                <FilterIcon />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <table id='entryTable' className='table entry-table'>
                                <thead>
                                    <tr>
                                        <th>{getText('rewards_column_date')}</th>
                                        <th>{getText('rewards_column_activity')}</th>
                                        <th>{getText('rewards_column_promotion')}</th>
                                        <th>{getText('rewards_column_reward')}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {entrylist}
                            </table>
                            {loadMoreLink}
                        </div>
                    </div>
                </ContentLoading>
            </div>
        );
    }
}
