import React from 'react';
import { connect } from 'react-redux';
import UserLockout from './../components/widgets/user_lockout';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import SEO from '../components/seo';
import RewardHistory from './../components/rewards/rewards';
import Layout from '../components/layouts/Layout';
import BackButton from '../components/widgets/back';

import '../components/rewards/style.scss';
import { getText } from '../utils/cms_copy_provider';

const Rewards = ({ user, notification, game, reward, points, config, actions }) => {
    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='Activity History' />
            <UserLockout>
                <div className='rewards primary-page-content'>
                    <div className='row'>
                        <div className='col-md-10 offset-md-1'>
                            <BackButton />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-10 offset-md-1 entry-container'>
                            <div className='description'>
                                <h2>{getText('rewards_title')}</h2>
                            </div>
                            <RewardHistory reward={reward} actions={actions} />
                        </div>
                    </div>
                </div>
            </UserLockout>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
