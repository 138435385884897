import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import './loading_style.scss';

/**
 * Will allow a div to avoid flickering into other views while loading data.
 * Wrap the <ContentLoading> tag around any item awaiting data to be loaded.
 *
 * Note: If the item being wrapped is awaiting load of an item that triggers the wrapper to reload,
 * there will be a callback loop. For instance, if the ContentLoader is wrapping the entries component,
 * but the entries component makes the call to getEntries, it causes the ContentLoader to refresh the
 * Entries Component, which in turn calls getEntries.
 *
 * based on this lesson: https://egghead.io/lessons/react-avoiding-state-flickers-in-gatsby-applications
 *
 * @param children     = wrapped elements
 * @param loadedAction = the string name of the action being waited on in LoadingStore
 * @param hasIcon      = true will show a grayed out area with a loading icon
 * @param loading      = comes from mapStateToProps
 */
const ContentLoading = ({ children, loadedAction, hasIcon, loading }) => {
    const [hasMounted, setHasMOunted] = useState(false);

    useEffect(() => {
        setHasMOunted(true);
    }, []);

    if (!hasMounted || loading.actions[loadedAction]) {
        if (hasIcon) {
            return (
                <div className='loading-wrapper'>
                    {children}
                    <div className='loading-overlay'>
                        <div className='loading-icon ellipses'></div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    return children;
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentLoading);
